import React, { useEffect, useMemo } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {
  CheckForLight,
  groupBy,
  roundedUp,
} from "../util/common/CommanFunction";
import { IconButton } from "@mui/material";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import useGenerateSeries from "../util/hooks/useGenerateSeries";
import moment from "moment/moment";

export const BlockSiteChart = (props) => {
  const {
    tableData,
    chartName,
    fallBack,
    WidgetName,
    from,
    isDashboard,
    customName,
    isStacked,
    siteList,
    blockName,
  } = props;

  const [showTable, setShowTable] = React.useState(false);
  const [tableContent, setTableContent] = React.useState("");
  const [chartOptions, setChartOptions] = React.useState({});
  console.log(props, "props");
  const isLight = CheckForLight();

  const ConvertTAPData = (data) => {
    let result = [];

    const { siteId, activePowerData } = data;

    activePowerData.forEach((item) => {
      result.push({
        siteId: siteId,
        timeStamp: item.timeStamp,
        irradiation: item.irradiation,
        totalActivePower: item.totalActivePower,
      });
    });

    return result;
  };

  const nameForFile = isDashboard ? customName : WidgetName;

  const dailyGenData = useGenerateSeries(
    tableData,
    [
      {
        key: "todayEnergy",
        displayName: "Today Energy",
        type: "column",
        axis: 0,
      },
      { key: "irradiation", displayName: "Irradiation", type: "line", axis: 1 },
    ],
    siteList
  );

  const specificYieldData = useGenerateSeries(
    tableData,
    [
      {
        key: "todayEnergy",
        displayName: "Today Energy",
        type: "column",
        axis: 0,
      },
      {
        key: "specificYield",
        displayName: "Specifc Yield",
        type: "line",
        axis: 2,
      },
    ],
    siteList
  );

  const processedPRData = useMemo(() => {
    if (tableData?.length === 0) return [];
    if (chartName === "Performance Ratio") {
      if (isStacked) {
        const length = groupBy(tableData, "siteId").length;
        return tableData.map((item) => {
          return {
            ...item,
            performanceRatio: roundedUp(item.performanceRatio / length),
          };
        });
      } else {
        return tableData;
      }
    }
  }, [isStacked, chartName]);

  const performanceRatioData = useGenerateSeries(
    processedPRData,
    [
      {
        key: "performanceRatio",
        displayName: "Performance Ratio",
        type: "column",
        axis: 3,
      },
    ],
    siteList
  );

  const revenueData = useGenerateSeries(
    tableData,
    [
      {
        key: "todayEnergy",
        displayName: "Today Energy",
        type: "column",
        axis: 0,
      },
      { key: "pvCost", displayName: "PV Cost", type: "line", axis: 4 },
      { key: "dgCost", displayName: "DG Cost", type: "line", axis: 4 },
      { key: "gridCost", displayName: "Grid Cost", type: "line", axis: 4 },
      { key: "pvRevenue", displayName: "PV Revenue", type: "column", axis: 5 },
      { key: "dgRevenue", displayName: "DG Revenue", type: "column", axis: 5 },
      {
        key: "gridRevenue",
        displayName: "Grid Revenue",
        type: "column",
        axis: 5,
      },
    ],
    siteList
  );

  const connvertedTAPData = useMemo(() => {
    if (chartName !== "Total Active Power") return [];
    if (tableData?.length === 0) return [];

    const result = tableData.map((item) => {
      return ConvertTAPData(item);
    });
    console.log(result, "result");

    return result.flat();
  }, [tableData, chartName]);

  const totalActivePowerData = useGenerateSeries(
    connvertedTAPData,
    [
      {
        key: "totalActivePower",
        displayName: "Total Active Power",
        type: "line",
        axis: 0,
      },
      {
        key: "irradiation",
        displayName: "Irradiation",
        type: "line",
        axis: 1,
      },
    ],
    isStacked ? [] : siteList,
    true
  );

  const currencyUnit = useMemo(() => {
    if (tableData?.length === 0) return "";
    return tableData[0]?.currencyUnits;
  }, [tableData]);

  const getSeriesData = (name) => {
    switch (name) {
      case "Daily Generation":
        return dailyGenData;
      case "Specific Yield":
        return specificYieldData;
      case "Performance Ratio":
        return performanceRatioData;
      case "Revenue Generation":
        return revenueData;
      case "Total Active Power":
        return totalActivePowerData;
      default:
        break;
    }
  };

  useEffect(() => {
    const chartOptions = {
      //chart details
      //chart config
      chart: {
        type: "column",
        backgroundColor: isLight ? "white" : "#121212",
      },

      //title
      title: {
        text: WidgetName,
        style: {
          textTransform: "captalize",
          color: isLight ? "black" : "white",
          fontWeight: "normal",
        },
      },

      //x axis config
      xAxis:
        chartName === "Total Active Power"
          ? {
              type: "datetime",
              dateTimeLabelFormats: {
                day: "%d/%m/%Y",
              },
              labels: {
                style: {
                  color: isLight ? "black" : "white",
                },
              },
              title: {
                text: "Time (HH:MM)",
                style: {
                  color: isLight ? "black" : "white",
                },
              },
            }
          : {
              type: "category",
              labels: {
                format: "{value:%b %d, %Y}",
                style: {
                  color: isLight ? "black" : "white",
                },
              },
              title: {
                text: "Date",
                style: {
                  color: isLight ? "black" : "white",
                },
              },
            },

      //y axis config
      yAxis: [
        {
          // 0
          title: {
            text: `Energy Generation ${
              chartName === "Total Active Power" ? "(kW)" : "(kWh)"
            }`,
            style: {
              color: isLight ? "black" : "white",
              fontSize: isDashboard ? "10px" : "14px",
            },
          },
          visible: chartName !== "Performance Ratio",
          style: {
            color: isLight ? "black" : "white",
            fontSize: isDashboard ? "10px" : "0.8em",
            fontFamily: "Inter",
          },
          labels: {
            style: {
              color: isLight ? "black" : "white",
              fontSize: isDashboard ? "10px" : "0.8em",
            },
          },
        },
        {
          //1
          min: 0,
          // max: 1000,
          title: {
            text: "Irradiation (Wh/m2)",
            style: {
              color: isLight ? "black" : "white",
              fontSize: isDashboard ? "10px" : "0.8em",
              fontFamily: "Inter",
            },
          },
          opposite: true,
          visible:
            chartName === "Daily Generation" ||
            chartName === "Total Active Power",
          labels: {
            style: {
              color: isLight ? "black" : "white",
              fontSize: isDashboard ? "10px" : "0.8em",
            },
          },
        },
        {
          //2
          title: {
            text: "Specific Yield",
            color: isLight ? "black" : "white",
            fontSize: isDashboard ? "10px" : "14px",
          },
          opposite: true,
          visible: chartName === "Specific Yield",
          min: 0,
          labels: {
            style: {
              color: isLight ? "black" : "white",
              fontSize: isDashboard ? "10px" : "14px",
            },
          },
        },
        {
          // 3
          title: {
            text: "Performance Ratio (%)",
            style: {
              color: isLight ? "black" : "white",
              fontSize: "0.8em",
              fontFamily: "Inter",
            },
          },
          min: 0,
          opposite: true,
          visible: chartName === "Performance Ratio",
          labels: {
            style: {
              color: isLight ? "black" : "white",
            },
          },
        },
        {
          //4
          title: {
            text: "Rate",
            style: {
              color: isLight ? "black" : "white",
              fontSize: "0.8em",
              fontFamily: "Inter",
            },
          },
          min: 0,
          opposite: true,
          visible: chartName === "Revenue Generation",
          labels: {
            style: {
              color: isLight ? "black" : "white",
            },
          },
        },
        {
          //5
          title: {
            text: `Revenue (${currencyUnit})`,
            style: {
              color: isLight ? "black" : "white",
              fontSize: "0.8em",
              fontFamily: "Inter",
            },
          },
          min: 0,
          opposite: false,
          visible: chartName === "Revenue Generation",
          labels: {
            style: {
              color: isLight ? "black" : "white",
            },
          },
        },
      ],

      colors: [
        // "#1E90FF",
        "#FF5733",
        "#32CD32",
        "#FFD700",
        "#FF1493",
        "#8A2BE2",
        "#00CED1",
        "#FF4500",
        "#7FFF00",
        "#DC143C",
        "#6495ED",
        "#FF8C00",
        "#00FA9A",
        "#8B4513",
        "#483D8B",
        "#00BFFF",
        "#FF6347",
        "#DA70D6",
        "#008080",
        "#ADFF2F",
      ],
      // data series config
      series: getSeriesData(chartName),

      //exporting config
      exporting: {
        filename: nameForFile + "-" + new Date().toLocaleDateString("en-GB"),
        csv: {
          dateFormat: "%d/%m/%Y",
        },
        buttons: {
          contextButton: {
            menuItems: [
              "viewFullscreen",
              "separator",
              "downloadPNG",
              "downloadSVG",
              "downloadPDF",
              "separator",
              "downloadCSV",
              "downloadXLS",
              from === "Vanalytics"
                ? {
                    text: "View Table",
                    onclick: function () {
                      const chart = this;
                      let dataHtml = chart.getTable(); // Generate HTML table from chart data

                      // Replace empty cells with 0
                      dataHtml = dataHtml.replace(
                        /<td class="highcharts-empty">\s*<\/td>/g,
                        '<td class="highcharts-number">0</td>'
                      );
                      // Format timestamps in the table
                      dataHtml = dataHtml.replace(
                        /<th class="highcharts-text" scope="row">(\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2})<\/th>/g,
                        function (match, p1) {
                          const date = new Date(p1);
                          return `<th class="highcharts-text" scope="row">${date.toLocaleDateString(
                            "en-GB",
                            {
                              day: "2-digit",
                              month: "numeric",
                              year: "numeric",
                            }
                          )}</th>`;
                        }
                      );
                      setTableContent(dataHtml); // Store the table content in state
                      setShowTable(true); // Show the table
                    },
                  }
                : "",
            ],
          },
        },
        enabled: true,
        // showTable: true,
        columnHeaderFormatter: function (item, key) {
          if (item.isXAxis) {
            return item.options.title.text || item.name;
          }
          if (key === "y") {
            return item.yAxis.options.title.text || item.name;
          }
        },
      },

      // plot options
      plotOptions: {
        column: {
          stacking: isStacked ? "normal" : null,
        },
        line: {
          marker: {
            enabled: false, // This also disables data points at the plot level
          },
        },
        series: {
          events: {
            legendItemClick: function () {
              const series = this.chart.series;
              const currentSeries = this;
              // console.log(series);

              if (series.length === 2) {
                const [series1, series2] = series;

                if (currentSeries === series1) {
                  // If series1 is clicked
                  if (series1.visible && !series2.visible) {
                    // Both series should be visible if series1 is visible and series2 is not
                    series1.update({ visible: true }, false);
                    series2.update({ visible: true }, false);
                  } else if (!series1.visible && series2.visible) {
                    // series1 should be visible and series2 should be hidden if series1 is not visible and series2 is visible
                    series1.update({ visible: true }, false);
                    series2.update({ visible: false }, false);
                  } else if (series1.visible && series2.visible) {
                    // If both are visible, make series2 invisible, and series1 remains visible
                    series1.update({ visible: true }, false);
                    series2.update({ visible: false }, false);
                  } else if (!series1.visible && !series2.visible) {
                    // If both are not visible, make both visible
                    series1.update({ visible: true }, false);
                    series2.update({ visible: true }, false);
                  }
                } else if (currentSeries === series2) {
                  // If series2 is clicked
                  if (series2.visible && !series1.visible) {
                    // Both series should be visible if series2 is visible and series1 is not
                    series1.update({ visible: true }, false);
                    series2.update({ visible: true }, false);
                  } else if (!series2.visible && series1.visible) {
                    // series2 should be visible and series1 should be hidden if series2 is not visible and series1 is visible
                    series1.update({ visible: false }, false);
                    series2.update({ visible: true }, false);
                  } else if (series1.visible && series2.visible) {
                    // If both are visible, make series1 invisible, and series2 remains visible
                    series1.update({ visible: false }, false);
                    series2.update({ visible: true }, false);
                  } else if (!series2.visible && !series1.visible) {
                    // If both are not visible, make both visible
                    series1.update({ visible: true }, false);
                    series2.update({ visible: true }, false);
                  }
                }

                // Finally, redraw the chart
                this.chart.redraw();
              } else {
                // Handle charts with more than two series or other cases
                this.chart.update(
                  {
                    series: series.map((s) => {
                      if (s === currentSeries) {
                        return {
                          visible: currentSeries.visible ? false : true,
                        };
                      } else {
                        const visbleSeries = series.filter(
                          (series) => series.visible
                        );
                        // console.log(
                        //   visbleSeries.length > 1 &&
                        //     visbleSeries.length < series.length - 1,
                        //   visbleSeries
                        // );

                        if (
                          visbleSeries.length > 1 &&
                          visbleSeries.length < series.length - 1
                        ) {
                          visbleSeries.forEach((series) => {
                            series.update({ visible: false }, false);
                          });
                        }
                        return {
                          visible: s.visible
                            ? visbleSeries.length > 1 &&
                              visbleSeries.length < series.length - 1
                              ? true
                              : false
                            : true,
                        };
                      }
                    }),
                  },
                  true
                );
              }

              return false; // Prevent the default action of hiding the clicked series
            },
          },
        },
      },

      //tooltip config
      tooltip: {
        useHTML: true,
        backgroundColor: isLight ? "white" : "#121212",
        formatter: function () {
          // console.log(this);
          const series = this.series;
          const point = this.point;
          if (!isStacked) {
            if (chartName === "Daily Generation") {
              return `
            <div class="custom-tooltip" style="color:${
              isLight ? "black" : "white"
            };">
            <b>${this.key}</b><br/>
              <div style="margin-bottom: 2px; margin-top:2px;">
                <b>${series.name.split(" - ")[1]}</b><br/>
              </div>
              <div>
                <span style="color:${point.color}; font-size:20px;">■ </span>${
                series.name.split(" - ")[0]
              }: ${this.y} ${
                series.name.split(" - ")[1] === "Today Energy"
                  ? "kWh"
                  : "Wh/m<sup>2"
              }
              </div>
            </div>
          `;
            } else if (chartName === "Specific Yield") {
              return `
              <div class="custom-tooltip" style="color:${
                isLight ? "black" : "white"
              };">
              <b>${this.key}</b><br/>
                <div style="margin-bottom: 2px; margin-top:2px;">
                  <b>${series.name.split(" - ")[1]}</b><br/>
                </div>
                <div>
                  <span style="color:${
                    point.color
                  }; font-size:20px;">■ </span>${
                series.name.split(" - ")[0]
              }: ${this.y} ${
                series.name.split(" - ")[1] === "Today Energy" ? "kWh" : ""
              }
                </div>
              </div>
            `;
            } else if (chartName === "Performance Ratio") {
              return `
              <div class="custom-tooltip" style="color:${
                isLight ? "black" : "white"
              };">
              <b>${this.key}</b><br/>
                <div style="margin-bottom: 2px; margin-top:2px;">
                  <b>${series.name.split(" - ")[1]}</b><br/>
                </div>
                <div>
                  <span style="color:${
                    point.color
                  }; font-size:20px;">■ </span>${
                series.name.split(" - ")[0]
              }: ${this.y} %
                </div>
              </div>
            `;
            } else if (chartName === "Revenue Generation") {
              return `
              <div class="custom-tooltip" style="color:${
                isLight ? "black" : "white"
              };">
              <b>${this.key}</b><br/>
                <div style="margin-bottom: 2px; margin-top:2px;">
                  <b>${series.name.split(" - ")[1]}</b><br/>
                </div>
                <div>
                  <span style="color:${
                    point.color
                  }; font-size:20px;">■ </span>${
                series.name.split(" - ")[0]
              }: ${this.y}
                  ${
                    series.name.split(" - ")[1] === "Today Energy"
                      ? `kWh`
                      : series.name.includes("Revenue") ||
                        series.name.includes("Cost")
                      ? currencyUnit
                      : ""
                  }
                </div>
              </div>
            `;
            } else if (chartName === "Total Active Power") {
              return `
            <div class="custom-tooltip" style="color:${
              isLight ? "black" : "white"
            };">
            <b>${moment(this.key).format("HH:mm")}</b><br/>
              <div style="margin-bottom: 2px; margin-top:2px;">
                <b>${series.name.split(" - ")[1]}</b><br/>
              </div>
              <div>
                <span style="color:${point.color}; font-size:20px;">■ </span>${
                series.name.split(" - ")[0]
              }: ${this.y} ${
                series.name.split(" - ")[1] === "Total Active Power"
                  ? "kWh"
                  : "Wh/m<sup>2"
              }
              </div>
            </div>
          `;
            }
          } else {
            if (chartName === "Daily Generation") {
              let tooltipContent = `
              <div class="custom-tooltip" style="color:${
                isLight ? "black" : "white"
              };">
                <b>${this.key}</b><br/>`;

              let totalValue = 0;
              this.series.chart.series.forEach((s) => {
                console.log(this.series, s.data);
                let matchingPoint = s.data.find(
                  (p) =>
                    p.series.name.split(" - ")[1] ===
                      this.series.name.split(" - ")[1] && p.x === this.x
                );
                if (matchingPoint) {
                  tooltipContent += `
                  <div>
                    <span style="color:${
                      matchingPoint.color
                    }; font-size:20px;">■ </span>
                    <b>${s.name.split(" - ")[0]}</b>: ${matchingPoint.y} ${
                    this.series.name.split(" - ")[1] === "Today Energy"
                      ? "kWh"
                      : "Wh/m<sup>2"
                  }
                  </div>`;
                  totalValue += matchingPoint.y;
                }
              });

              tooltipContent += `<hr/><b>Total:</b> ${totalValue} ${
                this.series.name.split(" - ")[1] === "Today Energy"
                  ? "kWh"
                  : "Wh/m<sup>2"
              }</div>`;
              return tooltipContent;
            } else if (chartName === "Specific Yield") {
              let tooltipContent = `
              <div class="custom-tooltip" style="color:${
                isLight ? "black" : "white"
              };">
                <b>${this.key}</b><br/>`;

              let totalValue = 0;
              this.series.chart.series.forEach((s) => {
                console.log(this.series, s.data);
                let matchingPoint = s.data.find(
                  (p) =>
                    p.series.name.split(" - ")[1] ===
                      this.series.name.split(" - ")[1] && p.x === this.x
                );
                if (matchingPoint) {
                  tooltipContent += `
                  <div>
                    <span style="color:${
                      matchingPoint.color
                    }; font-size:20px;">■ </span>
                    <b>${s.name.split(" - ")[0]}</b>: ${matchingPoint.y} ${
                    this.series.name.split(" - ")[1] === "Today Energy"
                      ? "kWh"
                      : "Wh/m<sup>2"
                  }
                  </div>`;
                  totalValue += matchingPoint.y;
                }
              });

              tooltipContent += `<hr/><b>Total:</b> ${totalValue} ${
                this.series.name.split(" - ")[1] === "Today Energy"
                  ? "kWh"
                  : "Wh/m<sup>2"
              }</div>`;
              return tooltipContent;
            } else if (chartName === "Performance Ratio") {
              let tooltipContent = `
              <div class="custom-tooltip" style="color:${
                isLight ? "black" : "white"
              };">
                <b>${this.key}</b><br/>`;

              let totalValue = 0;
              this.series.chart.series.forEach((s) => {
                console.log(this.series, s.data);
                let matchingPoint = s.data.find(
                  (p) =>
                    p.series.name.split(" - ")[1] ===
                      this.series.name.split(" - ")[1] && p.x === this.x
                );
                if (matchingPoint) {
                  tooltipContent += `
                  <div>
                    <span style="color:${
                      matchingPoint.color
                    }; font-size:20px;">■ </span>
                    <b>${s.name.split(" - ")[0]}</b>: ${matchingPoint.y} %
                  </div>`;
                  totalValue += matchingPoint.y;
                }
              });

              tooltipContent += `<hr/><b>Total:</b> ${totalValue} % </div>`;
              return tooltipContent;
            } else if (chartName === "Revenue Generation") {
              let tooltipContent = `
            <div class="custom-tooltip" style="color:${
              isLight ? "black" : "white"
            };">
              <b>${this.key}</b><br/>`;

              let totalValue = 0;
              this.series.chart.series.forEach((s) => {
                console.log(this.series, s.data);
                let matchingPoint = s.data.find(
                  (p) =>
                    p.series.name.split(" - ")[1] ===
                      this.series.name.split(" - ")[1] && p.x === this.x
                );
                if (matchingPoint) {
                  tooltipContent += `
                <div>
                  <span style="color:${
                    matchingPoint.color
                  }; font-size:20px;">■ </span>
                  <b>${s.name.split(" - ")[0]}</b>: ${matchingPoint.y} ${
                    this.series.name.split(" - ")[1] === "Today Energy"
                      ? "kWh"
                      : currencyUnit
                  }
                </div>`;
                  totalValue += matchingPoint.y;
                }
              });

              tooltipContent += `<hr/><b>Total:</b> ${totalValue} ${
                this.series.name.split(" - ")[1] === "Today Energy"
                  ? "kWh"
                  : currencyUnit
              }</div>`;
              return tooltipContent;
            } else if (chartName === "Total Active Power") {
              return `
            <div class="custom-tooltip" style="color:${
              isLight ? "black" : "white"
            };">
            <b>${moment(this.key).format("HH:mm")}</b><br/>
              <div style="margin-bottom: 2px; margin-top:2px;">
                <b>${blockName}</b><br/>
              </div>
              <div>
                <span style="color:${point.color}; font-size:20px;">■ </span>${
                series.name.split(" - ")[0]
              }: ${this.y} ${
                series.name === "Total Active Power" ? "kWh" : "Wh/m<sup>2"
              }
              </div>
            </div>
          `;
            }
          }
        },
      },

      //legend config
      legend: {
        maxHeight: 110,
        itemStyle: {
          color: isLight ? "black" : "white",
          fontSize: isDashboard ? "10px" : "14px",
        },
        itemHoverStyle: {
          color: isLight ? "gray" : "lightgray",
        },
      },

      //credits
      credits: {
        enabled: false,
      },
    };

    setChartOptions(chartOptions);
  }, [
    tableData,
    chartName,
    isStacked,
    isLight,
    isDashboard,
    WidgetName,
    from,
    nameForFile,
  ]);

  console.log(chartOptions, "chartOptions");

  return (
    <div
      style={{ width: "100%", height: from === "Vanalytics" ? "500px" : null }}
    >
      {tableData?.length > 0 ? (
        showTable ? (
          <div style={{ width: "100%" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <IconButton
                style={{
                  padding: "10px",
                  borderRadius: "10px",
                  height: "40px",
                  marginRight: "10px",
                }}
                variant="contained"
                size="small"
                onClick={() => setShowTable(false)}
              >
                <ArrowBackOutlinedIcon />
              </IconButton>
              <h2>{WidgetName?.split("-")[0]} Data Table</h2>
            </div>
            <div
              className="highcharts-data-table"
              dangerouslySetInnerHTML={{ __html: tableContent }}
            />
          </div>
        ) : (
          <HighchartsReact
            highcharts={Highcharts}
            containerProps={{
              style: {
                width: "100%",
                height: from === "Vanalytics" ? "500px" : null,
              },
            }}
            options={chartOptions}
          />
        )
      ) : (
        fallBack
      )}
    </div>
  );
};
